
                @import '@style/mixins';
                @import '@skillbox/front-box/dist/Functional/UiVariables/style';
              
@import 'node_modules/@skillbox/front-box/dist/Blocks/UiInfo/style';

.universal-info-details {
  @include media(md) {
    width: 696px;
  }

  @include media(lg) {
    width: 800px;
  }
}

.universal-info-details__wrapper {
  @include media(md) {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: 120px 1fr;
  }

  @include media(lg) {
    grid-gap: 40px;
  }
}

.universal-info-details__picture {
  width: 120px;
  height: 120px;
  border-radius: 20px;

  @include media(sm-max) {
    margin-bottom: 36px;
  }
}

.universal-info-details__desc {
  margin-bottom: 0;
}

.universal-info-details .universal-info-details__list {
  margin-top: 32px;
}

.universal-info-details .universal-info-details__list--with-title li {
  &:not(:last-child) {
    margin-bottom: 16px;

    @include media(lg) {
      margin-bottom: 24px;
    }
  }
}

.universal-info-details__list--with-title .universal-info-details__subtitle {
  display: block;
  margin-bottom: 8px;
}
